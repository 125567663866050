import React, {useEffect, useRef} from 'react';
import clsx from 'clsx';

import styles from './Dashboard.module.css';

interface DashboardProps {
  loading?: boolean;
}

export default function Dashboard({loading}: DashboardProps) {
  const frame = useRef<HTMLIFrameElement>(null);
  useEffect(() => {
    if (!loading && frame.current) {
      frame.current.src = frame.current.src;
    }
  }, [loading, frame]);
  return (
    <iframe
      className={clsx(styles['frame'], loading ? styles['loading'] : null)}
      src="https://api.os.dsscrm.pw/mochawesome.html"
      seamless
      ref={frame}
    ></iframe>
  );
}
