import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import {Private} from './routes';

export default function Application() {
  return (
    <BrowserRouter>
      <Private />
    </BrowserRouter>
  );
}
