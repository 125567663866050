import RestApi from '../utils/rest-api';

export type Handler<Response> = () => Promise<Response>;
export type Params = Record<string, any>;

const UNKNOWN_ERROR = 'Unknown error';

export default class Api {
  public getUrlWithParams(url: string, params: Params) {
    const searchParams = new URLSearchParams();
    Object.keys(params).forEach((key) => {
      if (params[key] != null) searchParams.append(key, params[key].toString());
    });
    return url + '?' + searchParams.toString();
  }

  private rest: RestApi;
  constructor(baseUrl: string) {
    this.rest = new RestApi(baseUrl);
  }
  get<Response>(url: string, headers?: Headers): Promise<Response> {
    return this.wrapRequest(() => this.rest.get(url));
  }
  post<Request, Response>(url: string, data?: Request, headers?: Headers): Promise<Response> {
    return this.wrapRequest(() => this.rest.post(url, data));
  }
  put<Request, Response>(url: string, data?: Request, headers?: Headers): Promise<Response> {
    return this.wrapRequest(() => this.rest.put(url, data));
  }
  patch<Request, Response>(url: string, data?: Request, headers?: Headers): Promise<Response> {
    return this.wrapRequest(() => this.rest.patch(url, data));
  }
  delete<Request, Response>(url: string, data?: Request, headers?: Headers): Promise<Response> {
    return this.wrapRequest(() => this.rest.delete(url, data));
  }
  private async wrapRequest<Response>(handler: Handler<Response>): Promise<Response> {
    try {
      return await handler();
    } catch (e) {
      throw e;
    }
  }
}
