import {useState, useCallback, useEffect, useRef} from 'react';
import api from '@app/api/rest';

interface UseRunResult {
  loading: boolean;
  run: () => void;
}

export default function useRun(): UseRunResult {
  const [loading, setLoading] = useState(true);
  var refTimer = useRef<ReturnType<typeof setInterval> | null>(null);
  const checkStatus = useCallback(async () => {
    const status = await api.runs.getStatus();
    setLoading(status === 'busy');
  }, [setLoading]);
  const startTimer = useCallback(() => {
    if (refTimer.current) return;
    refTimer.current = setInterval(checkStatus, 1000);
  }, [refTimer, checkStatus]);
  const stopTimer = useCallback(() => {
    if (refTimer.current) {
      clearInterval(refTimer.current);
      refTimer.current = null;
    }
  }, [refTimer]);
  useEffect(() => {
    if (loading) startTimer();
    else stopTimer();
  }, [loading, startTimer, stopTimer]);
  //
  const run = useCallback(() => {
    setLoading(true);
    api.runs.postRun();
  }, [setLoading]);
  //
  useEffect(() => {
    checkStatus();
  }, [checkStatus]);
  //
  return {
    loading,
    run,
  } as UseRunResult;
}
