import React, {useCallback, useEffect, useState, PropsWithChildren} from 'react';
import clsx from 'clsx';

import Dashboard from '../Dashboard';
import useRun from './useRun';

import styles from './Layout.module.css';

export default function Layout({children}: PropsWithChildren<{}>) {
  const {loading, run} = useRun();
  return (
    <>
      <header className={clsx(styles['header'], loading ? styles['loading'] : null)}>
        <button className={styles['button']} onClick={run}>
          Run
        </button>
      </header>
      <Dashboard loading={loading} />
    </>
  );
}
