import Api from './api';
import {Run} from '@app/types';

interface Status {
  status: 'busy' | 'active';
}

export default class Runs {
  constructor(private api: Api) {}
  async getStatus() {
    const result = await this.api.get<Status>('/status');
    return result.status;
  }
  postRun() {
    return this.api.post('/run');
  }
}
