import React from 'react';
import {Outlet, Routes, Route} from 'react-router-dom';

import {Dashboard} from '@views/.';
import Layout from '@components/Layout';

export default function Private() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout>
            <Outlet />
          </Layout>
        }
      >
        <Route index element={<Dashboard />} />
      </Route>
    </Routes>
  );
}
